<template>
    <div>
        <v-hover v-if="item">
            <v-card elevation="0" class="mx-auto h-full" flat tile @click="selectedjob">
                <v-list-item>
                    <v-list-item-action>
                        <v-btn fab small depressed color="primary">
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span class="primary--text">{{ item.title }}</span>
                            <!-- <template v-if="item.external === false">
                                <router-link
                                    :to="{ name: 'vacancies.details', params: { offer_id: item.id, offer_title: utils.replaceAll(item.title, ' ', '-') } }">
                                    {{ item.title }} </router-link>
                            </template> -->
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-icon small class="mr-1">{{ icons.mdiVectorCombine }}</v-icon>
                            <span class="sencondary--text">
                                Company: &nbsp;{{ (item.external === false) ? item.company.name : item.enterprise }}
                            </span>
                        </v-list-item-subtitle>
                        <v-divider class="subprimary my-2"></v-divider>

                        <v-list-item-subtitle>
                            <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon>
                            <span v-if="item.external === false">
                                {{ item.location }},( {{ item.country ? item.country.iso : '' }})
                            </span>
                            <span v-else>
                                {{ item.location }}
                            </span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.external === false">
                            {{ item.periodicity }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-icon small class="mr-1">{{ icons.mdiCloseCircle }}</v-icon>
                            <span>Dateline: </span>{{ item.expire_at }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon small color="primary">
                            {{ icons.mdiOpenInNew }}
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
            </v-card>
        </v-hover>
        <template v-else>
            <v-skeleton-loader class="mx-auto pb-2" max-width="100%" type="list-item-two-line" v-for="j in 5" :key="j">
            </v-skeleton-loader>
        </template>
    </div>
</template>
<script>
// mdiVideoInputComponent
import { mdiMagnify, mdiClose, mdiCloseCircle, mdiMapMarkerOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiVectorCombine, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
export default {
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {

            loaderdata: false,

            submit: true,
        }
    },
    computed: {
    },
    methods: {
        closeSearch() {
            this.loaderdata = true
            this.loadInitialJob()
        },

        selectedjob() {
            if (this.item.external === false) {
                this.$router.push({ name: 'vacancies.details', params: { offer_id: this.item.id, offer_title: Utils.replaceAll(this.item.title.trim(), ' ', '-') } })
            }
            else {
                this.$router.push({ name: 'vacancies.external.details', params: { offer_title: this.item.link.trim(), keyword: this.item.keyword.trim() } })
            }
        },
    },
    setup(props) {
        const icons = { mdiClose, mdiCloseCircle, mdiMapMarkerOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiVectorCombine, mdiOpenInNew }
        const utils = { Utils }

        return {
            icons, utils
        }
    }
}
</script>