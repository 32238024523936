<template>
    <div class="">
        <header-page :header="headers"></header-page>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <header-search-page :header="headersSearch" :loader="loader.loadSearchResult"
                        :result="searchDetails" @close="closeSearch" @action="searchActions">
                    </header-search-page>
                </v-col>
                <!-- <v-col class=''>
                            <v-card>
                                <v-card-text></v-card-text>
                            </v-card>
                        </v-col> -->
            </v-row>
            <v-row class="mx-auto" v-if="loader.loaderdata === false && no_resultitem <= 0">
                <v-col class="mx-auto">
                    <empty-data :text="nodatas"></empty-data>
                </v-col>
            </v-row>
            <v-row class="px-2 py-4 mx-auto">
                <v-col cols="12">
                    <div class="mx-auto" flat tile v-if="loader.loaderdata === false">
                        <div v-for="(item_u, i) in vacanciesItems" :key="i">
                            <vacancies-item-card :item="item_u" class="mb-1"></vacancies-item-card>
                            <!-- <v-divider class="my-1"></v-divider> -->
                        </div>
                    </div>
                    <template v-else>
                        <v-skeleton-loader class="mx-auto pb-2" max-width="100%" type="list-item-two-line"
                            v-for="(i, j) in 5" :key="j">
                        </v-skeleton-loader>
                    </template>
                </v-col>
                <!-- <v-col cols="4">
                            <v-skeleton-loader v-for="i in 6" class="mx-auto  mb-2" max-width="100%" type="article"
                                v-if="externalProposals.loader === true">
                            </v-skeleton-loader>
                            <div>
                                <v-card outlined rounded class="primary--text">
                                    <v-card-title class="primary--text">Related vacancies</v-card-title>
                                </v-card>
                                <v-hover class="mb-1" v-for="(item, k) in externalProposals.datas" :keys="k">
                                    <v-card outlined rounded @click="selectExternalProposal(item)">
                                        <v-card-text class="secondary--text">{{ item.title }}</v-card-text>
                                    </v-card>
                                </v-hover>
                            </div>
                        </v-col> -->

                <v-col>
                    <v-pagination class="text-center" circle v-model="page" :length="pages"
                        :total-visible="visiblePages" @input="updatevacanciesPage"></v-pagination>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// mdiVideoInputComponent
import { mdiMagnify, mdiMapMarkerOutline, mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import HeaderSearchPage from '@/views/components/HeaderSearchPage.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import VacanciesItemCard from '@/views/components/vacancies/VacanciesItemCard.vue';
import EmptyData from '@/views/components/utils/EmptyData.vue';
export default {
    components: {
        HeaderSearchPage,
        HeaderPage,
        VacanciesItemCard,
        EmptyData
    },
    data() {
        return {
            no_resultitem: 0,
            searchDetails: '',
            nodats: 'vacancies',
            loader: {
                loadSearchResult: false,
                loaderdata: false
            },
            externalProposals: {
                loader: true,
                size: 5,
                datas: []
            },
            submit: true,
            jobitem: {},
            vacancieslist: [],
            tmpsVacancieslist: [],
            headersSearch: {
                title: "Tops relevants jobs",
                subtitle: "Choose from over the most relevants jobs we have selected for you.",
                icon: '',
                search: {
                    offer: {
                        text: 'Search by offer',
                        icon: mdiMagnify
                    },
                    location: {
                        text: 'Search by location',
                        icon: mdiMapMarkerOutline
                    },
                    domain: {
                        text: 'Search by domain',
                        icon: mdiMapMarkerOutline
                    },
                    company: {
                        text: 'Search by company',
                        icon: mdiMapMarkerOutline
                    },
                }
            },
            headers: {
                titlepage: 'More vacancies',
                icon: this.icons.mdiMagnify
            },

            page: 1,
            pageSize: 10,
            visiblePages: 8,
            historyVacancies: [],
            listCount: 0,
        }
    },
    computed: {
        vacanciesItems() {
            return this.historyVacancies
        },
        pages() {
            if (this.pageSize == null || this.listCount == null) return 0;
            return Math.ceil(this.listCount / this.pageSize);
        }
    },
    methods: {
        selectExternalProposal(item) {
            var t_link = Utils.replaceAll(item.link, '/', '')
            t_link = Utils.replaceAll(item.link, 'fr', '')

            this.$router.push({ name: 'vacancies.external.details', params: { offer_title: t_link } })
        },
        closeSearch() {
            this.loader.loaderdata = true
            this.vacancieslist = this.tmpsVacancieslist
            this.initPagination()
            this.no_resultitem = 0
            this.loader.loaderdata = false
            // this.loadInitialJob()
        },

        searchActions(data) {
            // var search_str = `job.search?offer`
            this.loader.loadSearchResult = true
            this.loader.loaderdata = true
            const s_offer = data.title.isEmpty === false ? data.title.value : ''
            const s_domain = data.domain.isEmpty === false ? data.domain.value : ''
            const s_location = data.location.isEmpty === false ? data.location.value : ''
            const s_company = data.company.isEmpty === false ? data.company.value : ''

            this.tmpsVacancieslist = this.vacancieslist
            this.vacancieslist = []
            Drequest.api(`job.search?offer=${s_offer}&domain=${s_domain}&location=${s_location}&company=${s_company}`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.vacancieslist.push(value)
                        });
                        this.jobitem = response.listEntity[0]
                        this.searchDetails = response.detail
                        this.no_resultitem = response.nb_element
                        this.loader.loadSearchResult = false
                        this.loader.loaderdata = false
                    }
                    else {
                        this.loader.loadSearchResult = false
                        this.searchDetails = response.detail
                        this.loader.loaderdata = false
                        this.no_resultitem = 0
                    }
                })
                .catch((err) => {
                    this.loader.loadSearchResult = false
                    this.loader.loaderdata = false

                    this.no_resultitem = 0
                });
            // this.vacancieslist = this.methodsSearch(mainInputText)
            this.initPagination()
        },
        methodsSearch(in_name, in_location) {
            return this.vacancieslist.filter((item) => {
                return in_name.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
            })
        },
        async selectedjob(value) {
            this.$router.push({ name: 'vacancies.details', params: { offer_id: value.id, offer_title: Utils.replaceAll(value.title.trim(), ' ', '-') } })
            this.jobitem = value
        },
        async loadInitialJob() {
            this.loader.loaderdata = true
            Drequest.api(`job.combinealls`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.vacancieslist.push(value)
                        });
                        this.loader.loaderdata = false
                        this.no_resultitem = this.vacancieslist.length
                        this.initPagination()
                    }
                    else {
                        this.loader.loaderdata = false
                        this.no_resultitem = 0
                    }
                })
                .catch((err) => {
                    this.loader.loaderdata = false
                    this.no_resultitem = 0
                });
        },

        initPagination() {
            this.listCount = this.vacancieslist.length;
            if (this.listCount < this.pageSize) {
                this.historyVacancies = this.vacancieslist;
            } else {
                this.historyVacancies = this.vacancieslist.slice(0, this.pageSize);
            }
        },
        updatevacanciesPage(pageIndex) {
            let _start = (pageIndex - 1) * this.pageSize;
            let _end = pageIndex * this.pageSize;
            this.historyVacancies = this.vacancieslist.slice(_start, _end);
            this.page = pageIndex;
        }
    },
    beforeMount() {
        this.loadInitialJob()
        this.updatevacanciesPage(this.page);
    },
    setup(props) {
        const icons = { mdiClose, mdiMapMarkerOutline, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew }

        return {
            icons
        }
    }
}
</script>